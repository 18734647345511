import { memo, useRef } from 'react';

import cn from 'classnames';

import { useAssetUrl } from 'hooks/useAssetUrl';

import { useAutoPlay } from '../hooks/useAutoPlay';
import { useVolume } from '../hooks/useVolume';

import styles from './audio.module.scss';

type AudioProps = {
  active: boolean;
  audio: string;
  audioType?: string;
};

function Audio({ active, audio: audioPath, audioType }: AudioProps) {
  const src = useAssetUrl(audioPath);

  const audioRef = useRef(null);

  useVolume(audioRef);
  useAutoPlay({ active, playerRef: audioRef });

  return (
    <>
      {active && (
        <audio
          autoPlay
          className={cn(styles.audio)}
          controls
          loop
          ref={audioRef}
        >
          <source src={src} type={audioType} />
          Your browser does not support the audio tag.
        </audio>
      )}
    </>
  );
}

const MemoAudio = memo(Audio);

export { MemoAudio as Audio };

import { memo, useRef } from 'react';

import cn from 'classnames';

import { useAssetUrl } from 'hooks/useAssetUrl';

import { useAutoPlay } from '../hooks/useAutoPlay';

import styles from './video.module.scss';

type VideoProps = {
  active: boolean;
  video: string;
};

function Video({ active, video: videoPath }: VideoProps) {
  const src = useAssetUrl(videoPath);

  const videoRef = useRef(null);

  useAutoPlay({ active, playerRef: videoRef });

  return (
    <div className={styles.videoContainer}>
      {active && (
        <video
          autoPlay
          className={cn(styles.video)}
          controls
          loop
          muted
          playsInline
          ref={videoRef}
        >
          <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
}

const MemoVideo = memo(Video);

export { MemoVideo as Video };
